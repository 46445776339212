<template lang="pug">
#settings
  h1.main-page-title._margin(v-if="!isTmManager") Личные настройки
  .settings__row(v-if="!isTmManager")
    settings-item(:icon="UiIconNames.Settings_Account" label="Мой профиль" description="Информация о пользователе, смена логина и пароля" path="/settings/account")
    settings-item(:icon="UiIconNames.Settings_CogWheels" label="Настройки функциональности" description="Управление главным (боковым) меню, настройка работы с лотами и быстрым поиском, прочее" path="/settings/interface-settings")
    settings-item(v-if="enableSmsNotifications || enableEmailNotifications" :icon="UiIconNames.Icon_NotificationSmall" label="Уведомления" description="Настройка почтового адреса и графика уведомлений" path="/settings/notifications")
  h1.main-page-title._margin(:class="!isTmManager && 'settings-title__margin'") Настройки компании
  .settings__row
    settings-item(:icon="UiIconNames.Icon_Home" :label="roleAdmin || isTmManager ? 'Управление компанией' : 'Информация о компании'" description="Данные о компании" path="/settings/company-management")
    settings-item(v-if="enableLotManagement" :icon="UiIconNames.Icon_Calendar" label="Системные настройки" description="Информация об архивировании заявок" path="/settings/system-management")
    settings-item(v-if="roleAdmin && !isTmManager" :icon="UiIconNames.Menu_Money" label="Управление тарифами" description="Информация о подключенном тарифе, список доступных тарифов и дополнительных предложений" path="/settings/tariffs")
    settings-item(v-if="(roleAdmin && enableAccessControl) || isTmManager" :icon="UiIconNames.Settings_Group" label="Управление пользователями" description="Информация о подключенных пользователях, редактирование их данных" path="/settings/users-management")
    settings-item(v-if="enableSearchTab || enableLotManagement" :icon="UiIconNames.Icon_Tag" label="Управление тегами" description="Создание, редактирование и удаление тегов" path="/settings/tags")
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useUserAccess } from "@/use/userRoleAccess/useUserAccess";

import SettingsItem from "@/components/pages/settings/common/SettingsItem.vue";
import UiIconNames from "@/components/ui/icon/UiIconNames";

export default defineComponent({
  name: "SettingsMenu",
  components: {
    SettingsItem,
  },
  setup() {

    const {
      roleAdmin,
      isTmManager,
      enableSearchTab,
      enableLotManagement,
      enableAccessControl,
      enableSmsNotifications,
      enableEmailNotifications,
    } = useUserAccess();

    return {
      roleAdmin,
      isTmManager,
      enableSearchTab,
      enableLotManagement,
      enableAccessControl,
      enableSmsNotifications,
      enableEmailNotifications,
      UiIconNames,
    }
  }
});
</script>

<style scoped lang="scss">
@import "@/assets/styles/mixin/main";

#settings {
  @include adaptive-view;

  .settings__row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-row-gap: 80px;
    column-gap: 16px;
    justify-items: center;
  }

  .settings-title__margin {
    margin-top: 44px;
  }
}

@media (max-width: 720px) {
  #settings {
    .settings__row {
      grid-template-columns: 1fr 1fr;
    }
  }
}
</style>
