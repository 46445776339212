<template lang="pug">
router-link.settings-item(:to="path")
  .settings-item__border
    ui-icon.icon(:icon="icon" :size="24")
  span.label {{ label }}
  span.description {{ description }}
</template>

<script lang="ts">
import { defineComponent } from "vue";
import UiIcon from "@/components/ui/icon/UiIcon.vue";

export default defineComponent({
  name: "SettingsItem",
  components: {
    UiIcon,
  },
  props: {
    path: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
  },
});
</script>

<style scoped lang="scss">
.settings-item {

  --icon-size: 80px;
  --border-size: 134px;

  width: 100%;
  max-width: 280px;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  align-items: center;
  gap: 16px;

  .settings-item__border {
    display: flex;
    justify-content: center;
    align-items: center;
    height: var(--border-size);
    width: var(--border-size);
    border-radius: 50%;
    border: 1px solid var(--main-color-blue2);
    box-sizing: border-box;

    .icon {
      color: var(--main-color-blue);
      height: var(--icon-size);
      width: var(--icon-size);
    }
  }

  .label {
    font-size: 13px;
    line-height: 18px;
    font-weight: bold;
    text-transform: uppercase;
    color: var(--main-color-blue);
    justify-content: center;
    text-align: center;
  }

  .description {
    font-size: 13px;
    color: var(--main-placeholder-color);
    text-align: center;
  }

  &:hover {
    .label {
      text-decoration: underline;
    }

    .settings-item__border {
      background: var(--main-color-blue);
      .icon {
        color: var(--main-color-white);
      }
    }
  }
}

@media (max-width: 720px) {
  .settings-item {
    --icon-size: 40px;
    --border-size: 90px;
  }
}
</style>
